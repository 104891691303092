import React from 'react'
import * as cloudinary from '../../utilities/cloudinary'

export const ResponsiveImage = ({
  image,
  transformations = [],
  layoutsByBreakpoint = undefined,
  ...props
}) => {
  const parsedImage = cloudinary.parseCloudinaryUrlFromStrapiImage(image)
  const sources = cloudinary.getSourcesFromStrapiImage(
    parsedImage,
    transformations,
    layoutsByBreakpoint
  )

  // Each clause in the srcset links to the asset, then describes how wide the image is
  const srcset = sources
    .slice()
    .reverse()
    .reduce((acc, source) => {
      acc.push(`${source.src} ${source.naturalWidth}w`)
      return acc
    }, [])
    .join(', ')

  // Each clause in the `sizes` property describes (a) the breakpoint we're talking about
  // and (b) how wide the image will be rendered on the page
  const sizes = sources
    .reduce((acc, source, index) => {
      if (index < sources.length - 1) {
        acc.push(`${source.media} ${source.renderedWidth}px`)
      } else {
        acc.push(`${source.renderedWidth}px`)
      }
      return acc
    }, [])
    .join(', ')

  return (
    <img
      srcSet={srcset}
      sizes={sizes}
      alt={parsedImage.alternativeText}
      {...props}
    />
  )
}
