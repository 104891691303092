import React, { useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import * as styles from './homepage.module.css'
import TouchableCarousel from '../components/touchable-carousel'
import ScrollableCarousel from '../components/scrollable-carousel'
import { filter } from 'lodash'

const BREAKPOINT_TWO_COLUMN = 'two-column'
const BREAKPOINT_ONE_COLUMN = 'one-column'

const IndexPage = () => {
  // Get data
  const data = useStaticQuery(query)
  const homepage = data.strapiHomepage
  const projects = homepage.projects || []
  const body = homepage.body?.data?.childMarkdownRemark?.html || ''
  const featuredProjects = data.allStrapiProject.nodes

  const stage = useRef(null)
  const [offset, setOffset] = useState(undefined)
  useEffect(() => {
    if (!stage.current) {
      return
    }

    const offsetToUse =
      stage.current.getBoundingClientRect().top + window.pageYOffset
    setOffset(offsetToUse)
  }, [stage])


  /***
   * Adding prepended images from featuredImage field query on allStrapiProject
   */
  const imagesToPrepend = featuredProjects
    .map((project, index) => {
      const url = `/${project.slug}`
      const { date } = project

      let image
      if (project.thumbnail) {
        image = project.thumbnail
      } else if (project.images?.length > 0) {
        const projectAssetWithImage = project.images.find(
          (image) => image.image
        )
        image = projectAssetWithImage && projectAssetWithImage.image
      }
      return image && { id: index, url, image, date }
    })
    .filter((image) => image)

  /***
   *  add the homepage projects, use imagesToPrepend as the accumulator and filter out any pre-existing objects
   */
  const imagesToUse = projects
    .reduce((prepend, project, index) => {
      const url = `/${project.slug}`
      const { date } = project

      let image
      if (project.thumbnail) {
        image = project.thumbnail
      } else if (project.images?.length > 0) {
        const projectAssetWithImage = project.images.find((i) => i.image)
        image = projectAssetWithImage && projectAssetWithImage.image
      }

      // filter out images that exist in the accumulator array
      if (
        !image ||
        prepend.find(({ imageUrl }) => url === imageUrl) != 'undefined'
      ) {
        return prepend
      } else {
        return prepend.concat([{ id: index, url, image, date }])
      }
    }, imagesToPrepend)
    // sort by date
    .sort((a, b) => (a.date < b.date ? 1 : -1))

  const tallestImageAspectRatio = Math.min(
    ...imagesToUse.map(({ image }) => {
      return image.width / image.height
    })
  )

  // Watch for breakpoint changes
  const [breakpoint, setBreakpoint] = useState(null)
  useEffect(() => {
    const onResize = () => {
      setBreakpoint(
        window.innerWidth > 640 ? BREAKPOINT_TWO_COLUMN : BREAKPOINT_ONE_COLUMN
      )
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  })

  const locations = (homepage.locations || '')
    .split('\n')
    .map((l, i) => <p key={i}>{l}</p>)

  return (
    <Layout>
      <SEO />
      <div className={`page-content page-content--grow ${styles.page}`}>
        <article className={`${styles.content} homepage-content`}>
          {body && (
            <div
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            ></div>
          )}
        </article>
        {imagesToUse && (
          <div ref={stage} className={styles.images}>
            {breakpoint === BREAKPOINT_ONE_COLUMN ? (
              <TouchableCarousel
                images={imagesToUse}
                tallestImageAspectRatio={tallestImageAspectRatio}
                locations={locations}
                offset={offset}
              />
            ) : (
              <ScrollableCarousel
                images={imagesToUse}
                tallestImageAspectRatio={tallestImageAspectRatio}
                locations={locations}
                offset={offset}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    strapiHomepage {
      locations
      body {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      projects {
        id
        date
        slug
        images {
          image {
            alternativeText
            ext
            hash
            height
            mime
            name
            size
            url
            width
          }
        }
        thumbnail {
          alternativeText
          ext
          hash
          height
          mime
          name
          size
          url
          width
        }
      }
    }
    allStrapiProject(
      filter: { isFeatured: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        date
        slug
        images {
          image {
            alternativeText
            ext
            hash
            height
            mime
            name
            size
            url
            width
          }
        }
        thumbnail {
          alternativeText
          ext
          hash
          height
          mime
          name
          size
          url
          width
        }
      }
    }
  }
`
export default IndexPage
