import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import * as util from '../../utilities'
import * as styles from './index.module.css'
import { ResponsiveImage } from '../responsive-image'

const SCROLL_AMOUNT_PER_IMAGE = 100

const ScrollableCarousel = ({
  images,
  locations,
  tallestImageAspectRatio,
  offset,
}) => {
  const imageCount = images.length
  const [imageIndex, setImageIndex] = useState(0)
  const [carouselHeight, setCarouselHeight] = useState(undefined)
  const [sidebarHeight, setSidebarHeight] = useState(0)

  /*
    When
  */
  const stage = useRef(null)
  const locationsElement = useRef(null)
  useEffect(() => {
    if (
      typeof offset !== 'number' ||
      !stage.current ||
      !locationsElement.current
    ) {
      return
    }

    const stageBoundingBox = stage.current.getBoundingClientRect()
    // We know that the stage is spaced away from the viewport edge with `var(--space-outer)`.
    // Therefore we can calculate its right offset to get the value of the CSS var in pixels.
    const outerPadding = Math.round(window.innerWidth - stageBoundingBox.right)
    const sidebarHeight = window.innerHeight - offset - outerPadding
    const tallestImageHeight = Math.round(
      stageBoundingBox.width / tallestImageAspectRatio
    )
    const locationsHeight = Math.round(
      locationsElement.current.getBoundingClientRect().height
    )

    setSidebarHeight(sidebarHeight)

    if (tallestImageHeight + locationsHeight > sidebarHeight) {
      setCarouselHeight(sidebarHeight - locationsHeight)
    } else {
      setCarouselHeight(tallestImageHeight)
    }
  }, [offset, stage, locationsElement])

  // When the user scrolls, cycle through the images
  useEffect(() => {
    const onScroll = () => {
      const imageIndexToUse =
        Math.floor(window.pageYOffset / SCROLL_AMOUNT_PER_IMAGE) % imageCount
      setImageIndex(imageIndexToUse)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  })
  return (
    <div
      className={styles.stage}
      ref={stage}
      style={{
        height: `${sidebarHeight}px`,
        top: `${offset}px`,
      }}
    >
      {locations && (
        <div ref={locationsElement} className={`${styles.locations} heading`}>
          {locations}
        </div>
      )}
      <div
        className={styles.images}
        style={
          carouselHeight
            ? { height: `${carouselHeight}px`, flex: `0 0 ${carouselHeight}px` }
            : {}
        }
      >
        {images.map((entry, index) => {
          const isActive = imageIndex === index
          const imgEl = (
            <ResponsiveImage
              image={entry.image}
              layoutsByBreakpoint={0.5}
              className={styles.image}
              key={entry.id}
            />
          )
          return util.linkToIf(entry.url, entry.url, imgEl, {
            key: entry.id,
            className: styles.linkedImage,
            style: { display: isActive ? 'block' : 'none' },
            'aria-hidden': !isActive,
          })
        })}
      </div>
    </div>
  )
}

export default ScrollableCarousel
