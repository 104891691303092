import React, { useEffect, useRef, useState } from 'react'
import * as util from '../../utilities'
import { ResponsiveImage } from '../responsive-image'
import * as styles from './index.module.css'

const TouchableCarousel = ({
  images,
  locations,
  tallestImageAspectRatio,
  offset,
}) => {
  const imageCount = images.length
  const [imageIndex, setImageIndex] = useState(0)
  const [touchState, setTouchState] = useState({})
  const [overallHeight, setOverallHeight] = useState(null)
  const [carouselHeight, setCarouselHeight] = useState(null)

  const stage = useRef(null)
  const locationsLabel = useRef(null)

  useEffect(() => {
    if (
      typeof offset !== 'number' ||
      !stage.current ||
      !locationsLabel.current
    ) {
      return
    }

    const locationsHeight =
      locationsLabel.current.getBoundingClientRect().height
    const maxAllowedHeight = window.innerHeight - offset
    const carouselHeight = stage.current.offsetWidth / tallestImageAspectRatio
    setOverallHeight(
      `calc(${Math.round(maxAllowedHeight)}px - var(--spacer-outer-frame))`
    )
    setCarouselHeight(carouselHeight)
  }, [offset, stage, locationsLabel])

  const onTouchStart = (e) => {
    setTouchState({
      active: true,
      x: e.touches[0].screenX,
      distance: 0,
    })
  }

  const onTouchMove = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const prevX = touchState.x
    const prevDistance = touchState.distance
    const currentX = e.changedTouches[0].screenX
    const deltaX = Math.abs(currentX - prevX)
    const distance = prevDistance + deltaX
    if (distance > 100) {
      setImageIndex(imageIndex + 2 > imageCount ? 0 : imageIndex + 1)
      setTouchState({
        active: true,
        distance: 0,
        x: currentX,
      })
    } else {
      setTouchState({
        distance,
        active: true,
        x: currentX,
      })
    }
  }

  const onTouchEnd = (e) => {
    setTouchState({
      active: false,
    })
  }

  return (
    <div
      ref={stage}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchEnd}
      className={`${styles.stage} ${
        typeof overallHeight === 'string' && styles.isSized
      }`}
      style={{
        height: typeof overallHeight === 'string' ? overallHeight : 'auto',
      }}
    >
      {locations && (
        <div ref={locationsLabel} className={`${styles.locations} heading`}>
          {locations}
        </div>
      )}
      <div
        className={styles.images}
        style={{
          flex: `0 1 ${carouselHeight}px`,
        }}
      >
        {images.map((entry, index) => {
          const isActive = imageIndex === index
          const imgEl = (
            <ResponsiveImage
              image={entry.image}
              className={styles.image}
              key={entry.id}
            />
          )
          return util.linkToIf(entry.url, entry.url, imgEl, {
            key: entry.id,
            className: styles.linkedImage,
            style: { display: isActive ? 'block' : 'none' },
            'aria-hidden': !isActive,
          })
        })}
      </div>
    </div>
  )
}

export default TouchableCarousel
